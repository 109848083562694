
  // tslint:disable
  import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
  import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';


    /**
    * MFIGetProductRecommendationByStoreIdParameters entity interface.
    */
    export interface IMFIGetProductRecommendationByStoreIdParameters {
    StoreId?: string;
    NumberOfProducts: number;
    Id?: number;
    ExtensionProperties?: Entities.CommerceProperty[];
    }

    /**
    * MFIGetProductRecommendationByStoreIdResponse entity interface.
    */
    export interface IMFIGetProductRecommendationByStoreIdResponse {
    ProuctdRecommendationsData?: Entities.ProductSearchResult[];
    Message?: string;
    }

  /**
   * MFIGetProductRecommendationByStoreIdParameters entity class.
   */
  export class MFIGetProductRecommendationByStoreIdParametersExtensionClass implements IMFIGetProductRecommendationByStoreIdParameters {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StoreId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NumberOfProducts: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Id: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StoreId = odataObject.StoreId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NumberOfProducts = odataObject.NumberOfProducts;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Id = odataObject.Id;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }

      }
  }

  /**
   * MFIGetProductRecommendationByStoreIdResponse entity class.
   */
  export class MFIGetProductRecommendationByStoreIdResponseExtensionClass implements IMFIGetProductRecommendationByStoreIdResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProuctdRecommendationsData: Entities.ProductSearchResult[];

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Message: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ProuctdRecommendationsData = undefined;
        if (odataObject.ProuctdRecommendationsData) {
          this.ProuctdRecommendationsData = [];
          for (var i = 0; i < odataObject.ProuctdRecommendationsData.length; i++) {
            if (odataObject.ProuctdRecommendationsData[i]) {
        if (odataObject.ProuctdRecommendationsData[i]['@odata.type']) {
          var className: string = odataObject.ProuctdRecommendationsData[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProuctdRecommendationsData[i] = new EntityClasses[className](odataObject.ProuctdRecommendationsData[i]);
          }
        } else {
          this.ProuctdRecommendationsData[i] = new EntityClasses.ProductSearchResultClass(odataObject.ProuctdRecommendationsData[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ProuctdRecommendationsData[i] = undefined;
            }
          }
        }

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Message = odataObject.Message;

      }
  }
