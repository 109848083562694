import { ICoreContext } from '@msdyn365-commerce/core';
export const emailRegex = (emailValue: string) => {
    // eslint-disable-next-line security/detect-unsafe-regex
    const validationEmail = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]+(?:-+[a-z0-9]+)*\.)+(?:xn--[a-z0-9]+|[a-z]{2,16})$/i;

    return validationEmail.test(emailValue);
};

/**
 * Method to remove special characters for product name from product URL
 * @param str string
 * @param ctx ICoreContext
 * @returns string
 */

export const removeSpecialChars = (str: string, ctx: ICoreContext): string => {
    if (ctx && ctx.request.app?.config.removeSpecialCharsPDP) {
        return str?.replace(/[^a-zA-Z0-9- ]/g, '');
    }
    return str;
};


/**
 * Method to remove special characters for product URL's path
 * @param str string
 * @param ctx ICoreContext
 * @returns string
 */

export const removeSpecialCharsFromURLPath = (str: string, ctx: ICoreContext): string => {
    if (ctx && ctx.request.app?.config.removeSpecialCharsPDP) {
        const urlPath = decodeURIComponent(str.split('.p')[0])?.replace(/[^a-zA-Z0-9-/ ]/g, '');
        return `${urlPath}.p`;
    }
    return str;
};

// Following phone format is used on customer info section at checkout page
export const customerInfoPhoneRegex = /^\(?([2-9][0-8][0-9])\)?[ . ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/; // Valid USA number in format (xxx) xxx-xxxx

/*
This regex object has 2 patterns:
'nameRegex' matches names with letters, spaces, apostrophes, periods, and dashes.
'addressInputRegex' matches alphanumeric characters and printable ASCII symbols.
*/
export const inputRegexObj = {
    nameRegex: /^[\p{L}\s.'-]+$/u,
    addressInputRegex: /^[a-zA-Z0-9\x20-\x7E]*$/,
    phoneNumerRegex: customerInfoPhoneRegex
};

// This regex allows us to effectively validate and extract delivery messages
export const cartDeliveryMsgRegex = /[A-Za-z]{3} \d{1,2}(?: - [A-Za-z]{3} \d{1,2})?/;