import { IImageSettings, Image, IRequestContext } from '@msdyn365-commerce/core';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(tz);
dayjs.extend(utc);
dayjs.extend(isBetween);
import React from 'react';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
const format = 'M/D/YYYY H:m:s';

export interface IBadgeData {
    src: string;
    alt: string;
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
}

const defaultImageSettings: IImageSettings = {
    viewports: {
        xs: { q: `m=6`, w: 0, h: 0 }
    },
    disableLazyLoad: true
};

export const parseBadgeData = (badgeData: string, requestContext: IRequestContext): IBadgeData | undefined => {
    let dataSegments;
    let scheduleStart;
    let scheduleEnd;
    let badgeType;
    let parsedScheduleStart;
    let startDate;
    let startTime;
    let parsedScheduleEnd;
    let endDate;
    let endTime;

    try {
        // split the data into its segments
        dataSegments = badgeData.split('#');
        scheduleStart = dataSegments[0];
        scheduleEnd = dataSegments[1];
        badgeType = dataSegments[2];

        // parse schedule start
        parsedScheduleStart = scheduleStart.split('_');
        startDate = parsedScheduleStart[0];
        startTime = parsedScheduleStart[1];

        // parse schedule end
        parsedScheduleEnd = scheduleEnd.split('_');
        endDate = parsedScheduleEnd[0];
        endTime = parsedScheduleEnd[1];
    } catch (e) {
        return;
    }

    const startMoment = `${startDate} ${startTime?.replace(/-/g, ":")}`;
    const endMoment = `${endDate} ${endTime?.replace(/-/g, ":")}`;

    const startMomentFormatted = dayjs(startMoment?.replace(/-/g, "/"));
    const endMomentFormatted = dayjs(endMoment?.replace(/-/g, "/"));

    // check if schedule is valid
    if (!(startMomentFormatted.isValid() && endMomentFormatted.isValid())) {
        return;
    }

    // parse badge type and create image src
    const baseUrl = requestContext.apiSettings.baseImageUrl.substring(0, requestContext.apiSettings.baseImageUrl.length - 1);
    if (badgeType) {
        const removed$ = badgeType.substring(1, badgeType.length - 1);
        const parsedBadgeType = removed$?.replace(' ', '-').toLowerCase();

        // remove page name portion
        const altText = removed$.split('-')[0];

        const src = `${baseUrl}${parsedBadgeType}.png`;
        return {
            src: src,
            alt: altText,
            start: convertTimeZone(startMomentFormatted),
            end: convertTimeZone(endMomentFormatted)
        };
    } else {
        return;
    }
};

// convert given time to Central Time (CT)
const convertTimeZone = (time: dayjs.Dayjs): dayjs.Dayjs => {
    return dayjs(time.format(format), format).tz('America/Chicago').local();
};

export const buildProductBadge = (parsedBadgeData: IBadgeData, requestContext: IRequestContext): JSX.Element | undefined => {
    // only build image if it is scheduled
    const isScheduled = dayjs().isBetween(parsedBadgeData.start, parsedBadgeData.end);

    if (isScheduled) {
        return (
            <Image
                className={'mfrm-product-badge__image'}
                gridSettings={requestContext.gridSettings || {}}
                src={parsedBadgeData.src}
                altText={parsedBadgeData.alt}
                title={parsedBadgeData.alt}
                imageSettings={defaultImageSettings}
            />
        );
    }

    return undefined;
};

export const renderProductBadge = (
    attributeName: string | undefined,
    requestContext: IRequestContext
): JSX.Element | undefined => {
    const badgeData = attributeName;
    if (!badgeData) {
        return;
    }
    // split badge data into its separate badges
    const badges = badgeData.split(',');
    // parse badge data to get its schedule and image src
    const parsedBadges = badges.map(badge => parseBadgeData(badge, requestContext));

    // build product badge image components
    const finalizedBadges = parsedBadges.map(parsedBadge => parsedBadge && buildProductBadge(parsedBadge, requestContext));

    return finalizedBadges ? <>{finalizedBadges}</> : <></>;
};

export const renderProductBadgeByFilteringAttributes = (
    attributeName: string | undefined,
    attributes: AttributeValue[] | undefined,
    requestContext: IRequestContext
): JSX.Element | undefined => {
    const badgeData =
        attributeName &&
        attributes?.find(
            currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attributeName.toLowerCase().trim()
        )?.TextValue;
    if (!badgeData) {
        return;
    }

    // split badge data into its separate badges
    const badges = badgeData.split(',');

    // parse badge data to get its schedule and image src
    const parsedBadges = badges.map(badge => parseBadgeData(badge, requestContext));
    // build product badge image components
    const finalizedBadges = parsedBadges.map(parsedBadge => parsedBadge && buildProductBadge(parsedBadge, requestContext));

    return finalizedBadges && <>{finalizedBadges}</>;
};
