import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric,
    IRequestContext
} from '@msdyn365-commerce/core';
// import { ICategoryPath, ICategoryUrl } from '@msdyn365-commerce/core-internal/dist/types/interfaces/ICategoryPathInterfaces';
import { getCategoryPathsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { CategoryPathLookup, CategoryPathResult } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { hasArray } from '../Utilities/emtpyChecker';

/**
 * Category path input
 */
export class GetCategoryPathsInput implements IActionInput {
    public readonly ChannelId: number;
    public CatalogId?: number;
    public productIds?: number[];
    // public readonly categoryPathLooksups: CategoryPathLookup[];
    public readonly apiSettings: ICommerceApiSettings;
    // @ts-ignore
    private constructedCacheKey: string | undefined;

    constructor(context: IRequestContext, catalogId?: number, productIds?: number[]) {
        this.ChannelId = context.apiSettings.channelId;
        this.CatalogId = catalogId; // context.apiSettings.catalogId || 0;
        this.productIds = productIds;
        // this.categoryPathLooksups = categoryPathLooksups;
        this.apiSettings = context.apiSettings;
        this.constructedCacheKey = '';
        // categoryPathLooksups.forEach(categoryPath=> {
        //         this.constructedCacheKey+=`${categoryPath.ProductId && categoryPath.ProductId.toString()}|`;
        //     });
        this.constructedCacheKey = this.productIds?.map(ids => ids).join('-').toString();
        this.constructedCacheKey += `${this.ChannelId?.toString()}|`;
        this.constructedCacheKey += `${this.CatalogId?.toString()}|`;
    }

    public getCacheKey = () => `MFRMProductCategoryPath-${this.constructedCacheKey}`;
    public getCacheObjectType = () => 'MFRMProductCategoryPath';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Creates the input required to make the getCategoryPath retail api call
 */
export const createGetCategoryPathsInput = (
    inputData: GetCategoryPathsInput,
    context: ICreateActionContext<IGeneric<IAny>>
): IActionInput => {
    const productId = inputData.productIds;
    if (productId) {
        return new GetCategoryPathsInput(context.requestContext, inputData.CatalogId, inputData.productIds);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * Calls the Retail API and returns the category path for a product
 */
export async function getProductsCategoryPathsAction(input: GetCategoryPathsInput, ctx: IActionContext): Promise<CategoryPathResult[]> {
    const catPaths: CategoryPathLookup[] = [];
    if (!hasArray(input.productIds)) {
        return [];
    }
    for (let productsIter = 0; productsIter < input.productIds!.length; productsIter++) {
        catPaths.push({ ProductId: input.productIds![productsIter] });
    }
    try {
        return await getCategoryPathsAsync({ callerContext: ctx, queryResultSettings: { Paging: { Top: input.productIds!.length }, Sorting: {} } }, input.ChannelId, input.CatalogId!, catPaths);
    } catch (err) {
        console.log(err);
        return [];
    }
}

export const getCategoryPathsActionDataAction = createObservableDataAction({
    id: 'GetMFRMProductsCategoryPaths',
    action: <IAction<CategoryPathResult[]>>getProductsCategoryPathsAction,
    input: createGetCategoryPathsInput
});

export default getCategoryPathsActionDataAction;
