import MsDyn365, {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { OrgUnitLocation } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { IStoreSelectorState } from './store-selector-state';
/**
 * storeSelectorState - action input
 */
export class StoreSelectorStateInput implements IActionInput {
    public getCacheKey = () => `storeSelectorState`;
    public getCacheObjectType = () => 'storeSelectorState';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * StoreSelectorStateInput - action
 */
export async function storeSelectorStateAction(input: StoreSelectorStateInput, ctx: IActionContext): Promise<IStoreSelectorState> {
    const prefferedStore: OrgUnitLocation =
        MsDyn365.isBrowser && localStorage.getItem('_mfrm__prefferedStore_')
            ? JSON.parse(localStorage.getItem('_mfrm__prefferedStore_') || '')
            : {};
    return { isModalOpen: false, prefferedStore: prefferedStore };
}

/**
 * StoreSelectorStateInput - create new input for create action
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new StoreSelectorStateInput();
};

/**
 * StoreSelectorStateInput - create action
 */
export default createObservableDataAction<IStoreSelectorState>({
    id: 'StoreSelectorState',
    action: <IAction<IStoreSelectorState>>storeSelectorStateAction,
    input: createInput
});
