import React, { useState, useEffect, useRef } from 'react';

export const BVRatingSummary = ({
    itemId,
    onClick,
    variantId,
    hideReadReviewsButton
}: {
    itemId: string | undefined;
    onClick?: () => void;
    variantId?: number | undefined;
    hideReadReviewsButton?: boolean
}) => {
    const [dataBvProductIdAttr, setDataBvProductIdAttr] = useState(itemId);
    const wrapper = useRef<HTMLDivElement>(null);
    const interval = useRef(-1);
    const [numReviews, setNumReviews] = useState<string>();

    useEffect(() => {
        setDataBvProductIdAttr(itemId);
    }, [itemId]);

    useEffect(() => {
        setDataBvProductIdAttr('bv-refresh');
    }, [variantId]);

    useEffect(() => {
        if (dataBvProductIdAttr === 'bv-refresh') {
            setDataBvProductIdAttr(itemId);
        }
    }, [dataBvProductIdAttr]);
    useEffect(() => {
        const pollInterval = window.setInterval(() => {
            const numReviewsHTML = wrapper.current?.querySelector('.bv_numReviews_text')?.innerHTML.match(/\(([^)]+)\)/)?.[1];
            if (numReviewsHTML) {
                setNumReviews(numReviewsHTML);
                clearInterval(pollInterval);
            }
        }, 0);
    });
    useEffect(() => {
        if (wrapper.current) {
            numReviews === '0' ? wrapper.current.style.display = 'none' : wrapper.current.style.display = 'flex';
        }
    }, [numReviews]);

    return (
        <div
            onMouseEnter={() => {
                if (hideReadReviewsButton) {
                    interval.current = window.setInterval(() => {
                        const button: Element | undefined = Array.from(document.querySelectorAll('.bv_button_buttonFull')).find((button) => {
                            return button?.innerHTML === `Read ${numReviews} Reviews`;
                        });
                        if (button) {
                            button.outerHTML = '';
                        }
                    });
                }
            }}
            onMouseLeave={() => {
                if (hideReadReviewsButton) {
                    clearInterval(interval.current);
                }
            }}
            data-bv-show='rating_summary'
            data-bv-product-id={dataBvProductIdAttr}
            onClick={onClick}
            ref={wrapper}
        />
    );
};
